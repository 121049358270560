:root {
    --color-black: #000000;
    --color-white: #ffffff;
    --color-teal: #5DBFD4;
}

/* RESET / BASE STYLES */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: Arial, sans-serif;
    color: var(--color-black);
    background-color: var(--color-white);
}

a {
    text-decoration: none;
    color: var(--color-teal);
}

a:hover {
    opacity: 0.8;
}

/* CONTAINER */
.landing-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

/* HEADER */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-black);
    color: var(--color-white);
    padding: 1rem 2rem;
}

.logo {
    font-weight: bold;
    font-size: 1.25rem;
}

/* NAVIGATION */
.nav-bar {
    position: relative;
}

.hamburger {
    display: none;
    /* hidden by default for desktops */
    flex-direction: column;
    background: transparent;
    border: none;
    cursor: pointer;
}

.hamburger .line {
    width: 25px;
    height: 3px;
    background-color: var(--color-white);
    margin: 4px 0;
    transition: 0.4s;
}

/* NAV LINKS */
.nav-links {
    list-style: none;
    display: flex;
    gap: 1rem;
}

.nav-links li a {
    color: var(--color-white);
    transition: color 0.3s ease;
}

.nav-links li a:hover {
    color: var(--color-teal);
}

/* Mobile Styles */
@media (max-width: 768px) {
    .hamburger {
        display: flex;
        /* show hamburger on mobile */
    }

    /* hide nav links by default on mobile */
    .nav-links {
        position: absolute;
        top: 60px;
        /* slightly below the header */
        right: 0;
        background-color: var(--color-black);
        flex-direction: column;
        width: 200px;
        padding: 1rem;
        display: none;
    }

    .nav-links.open {
        display: flex;
        /* show nav when open class is added */
    }

    .nav-links li {
        margin-bottom: 1rem;
    }
}

/* HERO SECTION */
.hero {
    text-align: center;
    padding: 4rem 2rem;
    background-color: var(--color-teal);
    color: var(--color-white);
}

.hero h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.hero p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

.cta-button {
    background-color: var(--color-black);
    color: var(--color-white);
    padding: 0.75rem 1.5rem;
    border-radius: 0.25rem;
    transition: background-color 0.3s;
}

.cta-button:hover {
    background-color: #333;
}

/* FEATURES SECTION */
.features-section {
    padding: 3rem 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.features-section h2 {
    text-align: center;
    margin-bottom: 2rem;
}

.features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    gap: 2rem;
}

.feature-item {
    background-color: #f9f9f9;
    border-radius: 6px;
    text-align: center;
    padding: 1.5rem;
}

.feature-item .icon {
    font-size: 2rem;
    display: block;
    margin-bottom: 1rem;
}

.feature-item h3 {
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
}

/* HOW IT WORKS SECTION */
.hiw-section {
    padding: 3rem 2rem;
    text-align: center;
    background-color: #f3f3f3;
}

.hiw-section h2 {
    margin-bottom: 2rem;
}

.hiw-steps {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1.5rem;
    max-width: 700px;
    margin: 0 auto;
}

.hiw-steps .step {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    background-color: #fff;
    padding: 1rem;
    border-radius: 6px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.hiw-steps .step .icon {
    font-size: 2rem;
}

/* SCREENSHOTS SECTION */
.screenshots-section {
    text-align: center;
    padding: 3rem 2rem;
}

.screenshots-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin-top: 2rem;
}

.screenshot-placeholder {
    width: 300px;
    height: 200px;
    background-color: #eee;
    border: 2px dashed #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999;
}

/* ABOUT SECTION */
.about-section {
    padding: 3rem 2rem;
    background-color: #f3f3f3;
    text-align: center;
}

.about-section h2 {
    margin-bottom: 1rem;
}

/* FAQ SECTION */
.faq-section {
    padding: 3rem 2rem;
    max-width: 800px;
    margin: 0 auto;
}

.faq-section h2 {
    text-align: center;
    margin-bottom: 2rem;
}

.faq-section ul {
    list-style-type: none;
}

.faq-section li {
    margin-bottom: 1.5rem;
}

.faq-section strong {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

/* FOOTER */
.footer {
    margin-top: auto;
    background-color: #f1f1f1;
    padding: 2rem;
    text-align: center;
}

.footer-content {
    margin-bottom: 1rem;
}

.footer-content .links {
    margin-top: 0.5rem;
}

.footer-content .links a {
    margin: 0 0.75rem;
    color: var(--color-black);
    font-weight: 500;
}

.footer-content .links a:hover {
    color: var(--color-teal);
}